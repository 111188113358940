import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import { dropdownPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import Icons from '@aurora/shared-client/icons';
import type {
  BoardPagesAndParams,
  CategoryPageAndParams,
  GroupHubPageAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  Board,
  CoreNode,
  CoreNodeEdge
} from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import {
  EndUserComponent,
  EndUserPages,
  EndUserPathParams
} from '@aurora/shared-types/pages/enums';
import type { BreadCrumbJsonLdProps } from 'next-seo';
import { BreadcrumbJsonLd } from 'next-seo';
import React, { useContext } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import type { BreadcrumbFragment, NodeLinkFragment } from '../../../types/graphql-types';
import type { ComponentCommonProps } from '../../common/Widget/types';
import type { BreadcrumbItem } from '../../context/BreadcrumbContext/BreadcrumbContext';
import BreadcrumbContext from '../../context/BreadcrumbContext/BreadcrumbContext';
import useTranslation from '../../useTranslation';
import EntityLink from '../EntityLink/EntityLink';
import localStyles from './Breadcrumb.module.pcss';
import type { BreadcrumbWidgetProps } from './types';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';

export interface BreadcrumbProps extends ComponentCommonProps, BreadcrumbWidgetProps {
  /**
   * Whether the last crumb should be a disabled (not linked) in Desktop view.
   */
  disableLastCrumbForDesktop?: boolean;
  /**
   * Whether the bread crumb item shown is to navigate to parent of current context node in Mobile views.
   */
  useParentToNavigateForMobile?: boolean;
}

/**
 * Displays a depth path to the current location in case of desktop view and navigate to previous node option
 * in case of mobile. Allows for an action component to
 * be displayed in the breadcrumb in one of two ways: For components that contain the breadcrumb,
 * such as `BasePage`, there is a parameter exposed that allows the `actionComponent` to be set
 * on the `BreadcrumbContext` which will be rendered by this component. For components that are deeply
 * nested they can use a "portal" provided by the global state key `GlobalStateType.BREADCRUMB_PORTAL`.
 * The portal allows one to use the `ReactDOM.createPortal` to render an action component in the Breadcrumb.
 * See `BasePage` and `MessageAutosaveBreadcrumbFeedback` for examples of these two approaches for rendering
 * an action component in the `Breadcrumb`.
 *
 * @author Adam Ayres, Willi Hyde
 */
const Breadcrumb: React.FC<React.PropsWithChildren<BreadcrumbProps>> = ({
  disableLastCrumbForDesktop = true,
  useParentToNavigateForMobile = false
}: BreadcrumbProps) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.BREADCRUMB);
  const { community, contextNode } = useContext(AppContext);
  const { decorateBreadcrumbs, actionComponents } = useContext(BreadcrumbContext);
  const { router, Link } = useEndUserRoutes();
  const { baseUrl } = useContext(TenantContext);

  const breadcrumbs = new Map<string, BreadcrumbItem>();

  if (textLoading) {
    return null;
  }

  const node = contextNode.nodeType !== NodeType.COMMUNITY ? contextNode : community;

  breadcrumbs.set(community.id, {
    id: community.id,
    title: community.title,
    entity: community
  });

  if (node.nodeType !== NodeType.COMMUNITY) {
    const { ancestors } = node as unknown as BreadcrumbFragment;
    if (ancestors) {
      const nodeEdges = [...ancestors.edges];
      nodeEdges.forEach((edge: CoreNodeEdge) => {
        if (!breadcrumbs.has(edge.node.id)) {
          breadcrumbs.set(edge.node.id, {
            id: edge.node.id,
            title: edge.node.title,
            entity: edge.node
          });
        }
      });
    }

    if (!breadcrumbs.has(node.id)) {
      breadcrumbs.set(node.id, {
        id: node.id,
        title: node.title,
        entity: node
      });
    }
  }

  const decoratedBreadcrumbs = decorateBreadcrumbs([...breadcrumbs.values()]);

  /**
   * Renders a single breadcrumb.
   *
   * @param breadcrumb the breadcrumb to be rendered
   * @param index index of the breadcrumb
   * @param crumbClassName class name for the breadcrumb
   * @param forMobile if the breadcrumb is the one shown on the mobile view
   */
  function renderCrumb(
    breadcrumb: BreadcrumbItem,
    index: number | null,
    crumbClassName?: string,
    forMobile?: boolean
  ): React.ReactElement {
    const { title, entity, routeInfo } = breadcrumb;
    const classNames = crumbClassName || 'lia-crumb-item';
    const lastCrumb = index === decoratedBreadcrumbs.length - 1;

    if (lastCrumb && disableLastCrumbForDesktop) {
      return (
        <span title={title} className={cx(classNames)} aria-current={lastCrumb ? 'location' : null}>
          {title}
        </span>
      );
    }
    if (routeInfo) {
      return (
        <Link
          route={routeInfo.route}
          params={routeInfo.params}
          query={routeInfo.query}
          legacyBehavior={true}
        >
          <a className={cx(classNames)}>{title}</a>
        </Link>
      );
    }
    if (entity) {
      return (
        <EntityLink
          entity={entity as NodeLinkFragment}
          as="a"
          className={cx(classNames)}
          aria-current={lastCrumb ? 'location' : null}
          passHref
        >
          {forMobile && (
            <Icon
              icon={Icons.ChevronLeftIcon}
              size={IconSize.PX_16}
              color={IconColor.GRAY_700}
              className={cx('lia-crumb-icon-mobile')}
            />
          )}
          {title}
        </EntityLink>
      );
    }
    return (
      <span title={title} className={cx(classNames)} aria-current={lastCrumb ? 'location' : null}>
        {title}
      </span>
    );
  }

  /**
   * Renders breadcrumb(s) inside the dropdown.
   *
   * @param breadcrumb the breadcrumb to be rendered
   */
  function renderDropdownItem(breadcrumb: BreadcrumbItem): React.ReactElement {
    return (
      <React.Fragment key={breadcrumb.id}>
        {renderCrumb(breadcrumb, null, 'dropdown-item')}
      </React.Fragment>
    );
  }

  /**
   * Return the index of the mobile bread crumb.
   *
   * @returns Index of the mobile crumb
   */
  function getIndexOfMobileCrumb(): Number {
    const { length } = decoratedBreadcrumbs;
    if (
      useParentToNavigateForMobile ||
      (!useParentToNavigateForMobile && disableLastCrumbForDesktop)
    ) {
      return length - 2;
    } else {
      return length - 1;
    }
  }

  /**
   * Generates the url of the node types in bread crumb.
   *
   * @param crumb the breadcrumb info to compute the url
   * @returns url of the bread crumb items
   */
  function getCrumbUrl(crumb: BreadcrumbItem): String {
    if (!crumb?.entity) {
      return;
    }
    const { nodeType, displayId } = crumb.entity as CoreNode;
    switch (nodeType) {
      case NodeType.CATEGORY: {
        const routePath = router.getRelativeUrlForRoute<CategoryPageAndParams>(
          EndUserPages.CategoryPage,
          {
            [EndUserPathParams.CATEGORY_ID]: displayId
          }
        );
        return routePath;
      }
      case NodeType.BOARD: {
        const { parent } = crumb.entity as Board;
        const { boardRoute } = ConversationStyleBehaviorHelper.getInstance(crumb.entity as Board);
        const routePath = router.getRelativeUrlForRoute<BoardPagesAndParams>(boardRoute, {
          [EndUserPathParams.BOARD_ID]: displayId,
          [EndUserPathParams.CATEGORY_ID]: parent.displayId
        });
        return routePath;
      }
      case NodeType.GROUPHUB: {
        const routePath = router.getRelativeUrlForRoute<GroupHubPageAndParams>(
          EndUserPages.GroupHubPage,
          {
            [EndUserPathParams.GROUPHUB_ID]: displayId
          }
        );
        return routePath;
      }
      default: {
        return baseUrl;
      }
    }
  }

  /**
   * Return the breadcrumb Schema for all pages.
   *
   * @returns React Element - breadcrumb schema
   */
  function renderBreadcrumbSchema(): React.ReactElement {
    const itemListElements = decoratedBreadcrumbs.map((crumb, index) => {
      return {
        position: index,
        name: crumb.title,
        item: getCrumbUrl(crumb)
      };
    });
    const breadcrumbJsonLd: BreadCrumbJsonLdProps = {
      type: 'BreadcrumbList',
      itemListElements: itemListElements
    };
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <BreadcrumbJsonLd {...breadcrumbJsonLd} />;
  }

  const indexOfMobileCrumb = getIndexOfMobileCrumb();

  /**
   * Renders breadcrumb list item.
   *
   * @param breadcrumb the breadcrumb to be rendered
   * @param index index of the breadcrumb
   */
  function renderCrumbListItem(
    breadcrumb: BreadcrumbItem,
    index: number | null
  ): React.ReactElement {
    const forMobile = indexOfMobileCrumb === index;

    const classNames = cx('lia-crumb-list-item', {
      'lia-crumb-mobile': forMobile
    });

    return (
      <React.Fragment key={breadcrumb.id}>
        <li className={classNames} data-testid={forMobile ? 'Breadcrumb.Mobilecrumb' : null}>
          {renderCrumb(breadcrumb, index, null, forMobile)}
        </li>
        {index !== decoratedBreadcrumbs.length - 1 && (
          <li className={cx('lia-crumb-divider')} aria-hidden="true">
            <Icon
              icon={Icons.ChevronRightIcon}
              size={IconSize.PX_12}
              color={IconColor.GRAY_500}
              className={cx('lia-crumb-icon')}
            />
          </li>
        )}
      </React.Fragment>
    );
  }

  /**
   * Renders the breadcrumb dropdown.
   *
   * @param crumbs the array of breadcrumbs to be rendered in the dropdown
   */
  function renderDropdownCrumb(crumbs: BreadcrumbItem[]): React.ReactElement {
    return (
      <>
        <li key="dropdown" className={cx('lia-crumb-list-item')}>
          <Dropdown>
            <Dropdown.Toggle
              className={cx('lia-dropdown-toggle')}
              as={Button}
              aria-label={formatMessage('dropdown')}
              variant={ButtonVariant.UNSTYLED}
            >
              <Icon
                icon={Icons.EllipsisIcon}
                size={IconSize.PX_12}
                color={IconColor.GRAY_700}
                className={cx('lia-ellipsis-icon')}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu popperConfig={dropdownPopperConfig} renderOnMount>
              {crumbs.map(crumb => renderDropdownItem(crumb))}
            </Dropdown.Menu>
          </Dropdown>
        </li>
        <li className={cx('lia-crumb-divider')} aria-hidden="true">
          <Icon
            icon={Icons.ChevronRightIcon}
            size={IconSize.PX_12}
            color={IconColor.GRAY_500}
            className={cx('lia-crumb-icon')}
          />
        </li>
      </>
    );
  }

  /**
   * Renders the final breadcrumb list.
   *
   * @param localBreadcrumbs the array of breadcrumbs to be rendered
   */
  function renderBreadcrumbs(localBreadcrumbs: BreadcrumbItem[]): React.ReactElement {
    if (localBreadcrumbs.length <= 3) {
      return <>{localBreadcrumbs.map((crumb, index) => renderCrumbListItem(crumb, index))}</>;
    }

    const [firstCrumb] = localBreadcrumbs;
    const middleCrumbs = localBreadcrumbs.slice(1, -2);
    const remainingCrumbs = localBreadcrumbs.slice(-2);

    return (
      <>
        {renderCrumbListItem(firstCrumb, 0)}
        {renderDropdownCrumb(middleCrumbs)}
        {remainingCrumbs.map((crumb, index) =>
          renderCrumbListItem(crumb, index + middleCrumbs.length + 1)
        )}
      </>
    );
  }

  /**
   * Renders action components.
   */
  function renderActionComponents(): React.ReactElement {
    return (
      <div className={cx('lia-crumb-actions')}>
        {actionComponents.map(component => {
          const ActionComponent = component;
          return <ActionComponent key={component.name} />;
        })}
      </div>
    );
  }

  return (
    <>
      <ol className={cx('lia-crumb-list')} data-testid="Breadcrumb">
        {renderBreadcrumbs(decoratedBreadcrumbs)}
      </ol>
      {actionComponents && renderActionComponents()}
      {contextNode.nodeType !== NodeType.COMMUNITY && renderBreadcrumbSchema()}
    </>
  );
};

export default Breadcrumb;
